<template>
  <div class="content-wrap">
    <div class="filters">
      <!-- 评价状态 -->
      <ListFilter
        only
        :name="$t('train.evaluation_status')"
        :dataSource="evaluate"
        @onChange="evaluateChange"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value.trim="queryParam.keyword"
              :placeholder="$t('please_enter_keywords')"
              style="width: 240px"
              @search="reload"
            />
            <!-- 请输入关键字 -->
          </div>
        </template>
      </ListFilter>
    </div>
    <div class="table-wrap">
      <a-table
        size="middle"
        :scroll="{ x: 854 }"
        :columns="columns"
        :data-source="state.dataSource"
        rowKey="userId"
        :bordered="false"
        :pagination="pagination"
        :loading="state.loading"
        @change="handleTableChange"
      >
        <template #realName="{ record }">
          <div class="td-account">
            <div class="portrait">
              <img :src="record.portrait" alt="portrait" />
            </div>
            <div class="text">
              <p>{{ record.account }}</p>
              <p>
                <OpenData type="userName" :openid="record.realName" />
              </p>
            </div>
          </div>
        </template>
        <template #dateFormat="{ text }">
          {{ dateFormat(text) }}
        </template>
        <template #status="{ text }">
          <span v-if="text" style="color: #44b44f">{{
            $t("Evaluated")
          }}</span>
          <span v-else style="color: #e8673e">{{
            $t("homework.NotEvaluated")
          }}</span>
        </template>
        <template #lesScore="{ record }">
          {{ record.lesTime ? record.lesScore.toFixed(2) : '--' }}
        </template>
        <template #action="{ record }">
          <div class="action">
            <a-button
              type="link"
              v-if="record.lesTime"
              @click="openQuestionnaire(record, 1, 100)"
              >{{ $t('cm_view') }}</a-button
            >
            <a-button type="link" v-else @click="openQuestionnaire(record, 1)"
              >{{ $t('course.evaluate') }}</a-button
            >
          </div>
        </template>
      </a-table>
    </div>
  </div>

  <!-- 评价 -->
  <a-modal
    v-model:visible="questionnaireVisible"
    :title="$t('train.evaluation')"
    width="1050px"
    @ok="
      progress
        ? (questionnaireVisible = false)
        : $refs.questionnaireRef.submit()
    "
    :maskClosable="false"
    :keyboard="false"
    destroyOnClose
    :bodyStyle="{ overflow: 'auto', height: '70vh', padding: '0' }"
    :footer="null"
  >
    <questionnaire
      ref="questionnaireRef"
      :taskId="taskId"
      :did="detailId"
      :rid="rid"
      :userId="currUserId"
      :evalType="evalType"
      :progress="progress"
      source="teacher"
      @cancel="questionnaireCancel"
    />
  </a-modal>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useFetchData } from "@/utils/useFetchData";
import { dateFormat } from "@/utils/tools";
import { trainMembers } from "@/api/teacher";
import ListFilter from "@/components/filter/ListFilter.vue";
import OpenData from "@/components/OpenData.vue";
import questionnaire from "@/views/questionnaire/detail2";
export default {
  components: {
    ListFilter,
    OpenData,
    questionnaire,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    taskId: {
      type: Number,
      default: 0,
    },
    detailId: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const stateData = reactive({
      evaluate: [
        { id: 1, name: $t('Evaluated') }, // 已评价
        { id: 2, name: $t('homework.NotEvaluated') }, // 未评价
      ],
      columns: [
        {
          title: $t("Pub_Lab_UserName"),
          // title: "姓名",
          dataIndex: "realName",
          ellipsis: true,
          width: 180,
        },
        {
          title: $t("login.account"),
          // title: "账号",
          dataIndex: "account",
          ellipsis: true,
          width: 150,
        },
        {
          title: $t("homework.Department"),
          // title: "所属部门",
          dataIndex: "departmentName",
          ellipsis: true,
          width: 180,
        },
        {
          title: $t("CM_Status"),
          // title: "状态",
          dataIndex: "lesTime",
          width: 140,
          slots: { customRender: "status" },
        },
        {
          title: $t("LB_Favorite_Score"),
          // title: "评分",
          dataIndex: "lesScore",
          width: 160,
          slots: { customRender: "lesScore" },
        },
        {
          title: $t("AssessmentTime"),
          // title: "时间",
          dataIndex: "lesTime",
          width: 160,
          slots: { customRender: "dateFormat" },
        },
        {
          title: $t("homework.table.operate"),
          // title: "操作",
          dataIndex: "action",
          fixed: "right",
          align: "center",
          width: 90,
          slots: { customRender: "action" },
        },
      ],
      // columns: [
      //   {
      //     title: $t('train.student'),
      //     // title: "学员",
      //     width: 310,
      //     slots: { customRender: "realName" },
      //   },
      //   {
      //     title: $t('LB_Favorite_Score'),
      //     // title: "评分",
      //     dataIndex: "lesScore",
      //     width: 120,
      //     slots: { customRender: "lesScore" },
      //     align: 'right',
      //   },
      //   {
      //     title: $t('train.grading_time'),
      //     // title: "评分时间",
      //     dataIndex: "lesTime",
      //     width: 220,
      //     slots: { customRender: "dateFormat" },
      //     align: 'center',
      //   },
      //   {
      //     title: $t('cm_operate'),
      //     // title: "操作",
      //     dataIndex: "action",
      //     width: 100,
      //     slots: { customRender: "action" },
      //   },
      // ],
      questionnaireVisible: false, //问卷
      rid: 0, //评估表id
      evalType: 0, //评估类型 0任务使用 2学员评价老师 3学员评价课程 4讲师评价学员
      currUserId: 0,
      progress: 0,
    });

    const queryParam = reactive({
      taskId: props.taskId,
      detailId: props.detailId,
      keyword: "",
      isEvaluate: 0,
    });

    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return trainMembers(form).then((res) => {
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };

    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 10,
      tableSize: "middle",
      stripe: false,
    });

    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });
      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["10", "30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    const evaluateChange = (arr) => {
      queryParam.isEvaluate = arr[0];
      reload();
    };

    const openQuestionnaire = (item, evalType, progress) => {
      stateData.rid = props.data.lesId;
      stateData.currUserId = item.userId;
      stateData.evalType = evalType;
      stateData.progress = progress || 0;
      stateData.questionnaireVisible = true;
    };

    const questionnaireCancel = () => {
      stateData.questionnaireVisible = false;
      reload();
    };

    return {
      dateFormat,
      ...toRefs(stateData),
      state,
      pagination,
      handleTableChange,
      queryParam,
      reload,
      evaluateChange,
      openQuestionnaire,
      questionnaireCancel,
    };
  },
};
</script>

<style lang="less" scoped>
.content-wrap {
  background-color: #fff;
  padding: 0 24px 14px;
  .filters {
    ::v-deep(.filter) {
      .label {
        width: 78px !important;
      }
      .values {
        width: calc(100% - 88px) !important;
      }
    }
    .search {
      .mixinFlex(flex-end);
    }
  }
  .table-wrap {
    min-height: 756px;
    margin-top: 10px;
    ::v-deep(td) {
      vertical-align: middle !important;
    }
    ::v-deep(.ant-table-pagination.ant-pagination) {
      float: none;
      text-align: center;
    }
    .action {
      ::v-deep(.ant-btn) {
        padding-right: 4px;
        padding-left: 0;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .td-account {
      .mixinFlex(space-between; center);
      .portrait {
        .mixinImgWrap(40px; 40px);
        border-radius: 50%;
      }
      .text {
        width: calc(100% - 50px);
        p {
          margin: 0;
          font-size: 14px;
          color: #666;
          line-height: 20px;
        }
      }
    }
    .td-score {
      color: @color-theme;
      font-size: 16px;
      i {
        font-size: 30px;
        font-style: normal;
      }
    }
  }
}
</style>